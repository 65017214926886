<template>
  <div class="classFication">
    <Heador />
    <classBody />
      <NavBar v-if='!storeId' activeFlag='1'/>
      <NavBarAlone v-else activeFlag='1'/>

  </div>
</template>

<script>
import Heador from "./cheader";
import classBody from "./classbody";
export default {
  data() {
    return {
      storeId: localStorage.storeId,
    };
  },
  components: {
    Heador,
    classBody,
  },
  created() {
    if(!localStorage.getItem('appid')) {
      this.getConfigAppId()
    }
  },
  methods: {
    getConfigAppId() {
      this.$api.login
          .getConfigLogoUrl({
            k: 'APPID',
          })
          .then((res) => {
            // 成功
            if (res.errno === 200) {
               
              localStorage.setItem('appid', res.data.valueWorth)
            } else {
              this.$toast(res.errmsg);
            }
          });
    },
  }
};
</script>

<style lang="less" scoped>
.classFication {
  width: 100%;
  box-sizing: border-box;
  // background-color: #f6f6f6;
  padding: 0 0 1.28rem /* 64/50 */;
}
</style>