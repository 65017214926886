<template>
  <div class="classbody dark_bg">
    <transition name="fade">
      <preLoading v-if="isLoading"></preLoading>
    </transition>
    <div class="menuwrapper dark_bg" ref="menuWrapper">
      <van-sidebar :active-key="activeKey" @change="onChange" class="content">
        <van-sidebar-item
          v-for="(item, index) in slidebarItem"
          :key="index"
          :title="item.title"
          @click="tabtap(item)"
        />
      </van-sidebar>
    </div>
    <van-loading type="spinner" v-if="showLoading" />
    <div class="goodswrapper dark_bg" ref="goodsWrapper" v-else>
      <van-row gutter="4" type="flex">
        <h3 class="list-title">推荐品牌</h3><br />
        <van-col
          span="8"
          v-for="item in slideContent"
          :key="item.id"
          @click.native="clickActive(item.id)"
        >
          <div>
            <p class="class-img">
              <img :src="item.picUrl" />
            </p>

            <p class="class-txt">{{ item.title }}</p>
          </div>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import { Sidebar, SidebarItem, Row, Col, Collapse, Loading } from "vant";
import BScroll from "@better-scroll/core";
export default {
  data() {
    return {
      activeKey: 0,
      showLoading: false,
      slidebarItem: [],
      slideContent: [],
      isLoading: true,
    };
  },
  components: {
    [Sidebar.name]: Sidebar,
    [SidebarItem.name]: SidebarItem,
    [Row.name]: Row,
    [Col.name]: Col,
    [Loading.name]: Loading,
  },
  created() {
    this.getClassList();
  },
  computed: {
    current: function () {
      return this.slidebarItem[0].categoryName;
    },
  },
  methods: {
    onChange(key) {
      this.activeKey = key;
    },
    // 初始化滚动值
    initFirstScroll() {
      if (!this.scroll) {
        this.scroll = new BScroll(this.$refs.menuWrapper, {
          probeType: 3,
          click: true,
          // scrollY: true,
          // bounce: {
          //   left: false,
          //   right: false,
          //   top: true,
          //   bottom: true
          // },
          // scrollX: false,
          // mouseWheel: {
          //   // pc端同样能滑动
          //   speed: 20,
          //   invert: false
          // },
          // useTransition: true // 防止iphone微信滑动卡顿
        });
      } else {
        this.scroll.refresh();
      }
    },
    initSecondScroll() {
      // if (!this.goodscroll) {
      this.goodscroll = new BScroll(this.$refs.goodsWrapper, {
        probeType: 3,
        click: true,
        // bounce: {
        //   left: false,
        //   right: false,
        //   top: true,
        //   bottom: true
        // },
        // scrollY: true,
        // scrollX: false,
        // mouseWheel: {
        //   // pc端同样能滑动
        //   speed: 20,
        //   invert: false
        // },
        // useTransition: true // 防止iphone微信滑动卡顿
      });
    },
    // 一级分类数据
    getClassList() {
      this.$api.classfication.oneClassListAddress({ type: "1" }).then((res) => {
        if (res.errno === 200) {
          this.slidebarItem = res.data;
          this.slideContent = res.data[0].childrenList;
          this.currentId = res.data[0].id;
          this.$nextTick(() => {
            this.initFirstScroll();
          });
          // this.getSecondClassTab(this.slidebarItem[0].id);
          // 默认显示第一个父分类的子级目录
          this.isLoading = false;
        }
      });
    },
    // 二级分类数据
    // getSecondClassTab(id) {
    //   this.showLoading = true;
    //   this.$api.classfication
    //     .secondClassListAddress({
    //       categoryId: id
    //     })
    //     .then(res => {
    //       if (res.code == 100) {
    //         this.showLoading = false;
    //         this.slideContent = res.returnValue;
    //         this.isLoading = false;
    //         // 初始化better-scroll  //该方式没有行通
    //         this.$nextTick(() => {
    //           this.initSecondScroll();
    //         });
    //         // 初始化better-scroll 转用定时器
    //         // setTimeout(this.initSecondScroll, 200);
    //       }
    //     });
    // },
    //一级分类点击
    tabtap(item) {
      this.currentId = item.id;
      this.slideContent = item.childrenList;
      this.$nextTick(() => {
        this.initSecondScroll();
      });
    },
    clickActive(id) {
      this.$router.push(`/secondclass/${this.currentId}/goods/${id}`);
    },
  },
};
</script>

<style lang='less' scoped>
@import "../../assets/styles/element.less";
.classbody {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 1rem /* 50/50 */;
  bottom: 1.2rem /* 60/50 */;
  left: 0;
  right: 0;
  overflow: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
  .list(row);
  .menuwrapper {
    // background-color: #f6f6f6;
    .van-sidebar {
      // background-color: #fff;
      // background-color: #f6f6f6;

      // padding: 0.32rem /* 16/50 */ 0.12rem /* 6/50 */;
    }
    .van-sidebar-item {
      // background-color: #f0f0f0;
    // background-color: #f6f6f6;

      border: none;
      text-align: center;
      // padding: 0.32rem /* 16/50 */ 0rem;

      height: .25rem;
      font-size: .26rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #7E7E7E;
      line-height: .49rem;
      &.van-hairline::after {
        border: none;
      }
    }
    .van-sidebar-item--select {
      // color: #fff;
      // border: none;
      // border-radius: 0.64rem /* 32/50 */;
      // padding: 0.1rem /* 5/50 */ 0.1rem /* 5/50 */;
      // background-color: #cd4133;

      font-size: .26rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      line-height: .49rem;
    }
  }
  .van-loading {
    margin: 0.32rem /* 16/50 */ auto;
  }
  .goodswrapper {
    width: 100%;
    box-sizing: border-box;
    padding: 0 0.06rem /* 3/50 */ 0;
    // background: #f6f6f6;
    .van-row {
      position: relative;
      margin-top: .4rem;
      margin-right: .3rem;
      padding-top: .86rem;
      padding-bottom: .3rem;
      flex-wrap: wrap;
          background-color: #fff;
          box-shadow: 0px 0px .1rem 0px rgba(0, 0, 0, 0.05);
          border-radius: .2rem;

      .van-col {
        flex-grow: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        
        & > div {
          // background-color: #fff;
          text-align: center;
          border-radius: 0.06rem /* 3/50 */;
          margin-bottom: 4px;
        }
        .class-img {
          // height: 1.76rem /* 88/50 */;
          // width: 100%;
          width: 1.26rem;
          height: 1.24rem;
          img {
            width: 100%;
            height: 100%;
            border-top-left-radius: 0.06rem /* 3/50 */;
            border-top-right-radius: 0.06rem /* 3/50 */;
          }
        }
        p.class-txt {
          // font-size: 0.24rem /* 12/50 */;
          // box-sizing: border-box;
          // padding: 0.12rem /* 6/50 */ 0;

          font-size: .24rem;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: .27rem;
        }
      }
    }
  }
}
.van-sidebar-item--select::before{
  top:100%;
  height: .56rem;
  left: .1rem;
  background: @van_sidebar_selected_border_color;
}
.list-title{
  position: absolute;
  top: .3rem;
  left: .3rem;
  font-size: .28rem;
font-family: PingFang SC;
font-weight: bold;
color: #333333;
line-height: .49rem;
}
.van-sidebar-item--select, .van-sidebar-item--select:active{
  background: transparent;
}
.van-sidebar-item{
  background-color: #f6f6f9;
}
</style>
