
<template>
  <section class="class-header dark_bg">
    <div @click="goSearch" class="dark_bg">
      维生素胶囊
      <span class="iconfont icon-sousuo dark_span"></span>
    </div>
    <!-- <p>
       <span class="iconfont icon-xiaoxi" @click="show" ></span>
        <router-link @click="show">消息</router-link>
       <span @click="show" style="font-size: 0.26rem">消息</span>
    </p> -->
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  methods: {
    // 跳转到搜索页面
    goSearch() {
      this.$router.push({ name: "search" });
    },
    show() {
      this.$router.push('/customerChat')
      // this.$toast("敬请期待");
    },
  },
};
</script>

<style lang='less' scoped>
@import "../../assets/styles/element.less";
.class-header {
  width: 100%;
  box-sizing: border-box;
  padding: 0.16rem /* 8/50 */ 0.32rem /* 16/50 */;
  height: 0.96rem /* 48/50 */;
  background-color: #fff;
  .list(row);
  font-size: 0.24rem /* 12/50 */;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dbdbdb73;
  & > div {
    width: 90%;
    // width: 100%;
    padding-left: 0.76rem /* 38/50 */;
    border-radius: 0.2rem /* 16/50 */;
    // background-color: #fff;
    background: #F6F6F9;
    position: relative;
    // border: 1px solid #d33f3f;
    height: 0.7rem /* 26/50 */;
    line-height: 0.7rem /* 26/50 */;
    color: rgb(155, 154, 154);
    span {
      position: absolute;
      top: 1px;
      color: #BFBFBF;
      font-weight: bold;
      // left: 8px;
      right: 8px;

      font-size: 0.30rem /* 18/50 */;
    }
  }
  & > p {
    text-align: center;
    color: #c83f3f;
    span {
      display: block;
      // font-size: 0.36rem /* 22/50 */;
      font-size: 0.5rem /* 22/50 */;
      color: #333;
    }
    a {
      color: #c83f3f;
    }
  }
}
</style>
